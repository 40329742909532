import React, { useState } from "react";
import {Form, Button, Container, Row, Col} from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function Login() {
  // initial state
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [login, setLogin] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const configuration = {
      method: "post",
      url: "https://neomenu-backend.fly.dev/login/waiter",
      data: {
        code,
        password,
      },
    };

    axios(configuration)
      .then((result) => {
        console.log(result.data)
        cookies.set("NEO_WAITER_TOKEN", result.data.token, {
          path: "/",
        });
        cookies.set("NEO_WAITER_RESTAURANT_ID", result.data.restaurantId, {
          path: "/",
        })
        window.location.href = "/";

        setLogin(true);
      })
      .catch((error) => {
        error = new Error();
      });
  };

  return (
    <Container className="pt-4">
      <Row>
        <Col></Col>
        <Col className="col-md-4 col-12" style={{minWidth: "400px", maxWidth: "500px"}}>
          <div className="p-5" style={{boxShadow: "0px 5px 30px -10px rgb(0 0 0 / 10%)"}}>
            <h2>Login</h2>
            <Form onSubmit={(e) => handleSubmit(e)}>
            <Form.Group>
              <Form.Label>Waiter code</Form.Label>
              <Form.Control
                type="text"
                name="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Enter code"
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </Form.Group>

            <Button
              className="mt-4 mb-2 w-100"
              variant="success"
              type="submit"
              onClick={(e) => handleSubmit(e)}
            >
              Login
            </Button>
          </Form>
          </div>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}
