import {Accordion, Button, Col, Container, Modal, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import RevokeDiv from "./RevokeDiv";
import styled from "@emotion/styled";
import QRCode from "react-qr-code";

const cookies = new Cookies();

export default function Waiter() {

  const [tables, setTables] = useState([]);
  const [filteredStatus, setFilteredStatus] = useState(null);

  const token = cookies.get("NEO_WAITER_TOKEN");
  const restaurantId = cookies.get("NEO_WAITER_RESTAURANT_ID");

  useEffect(() => {
    const updateTablesDataInterval = setInterval(updateTablesData, 3000);

    // Cleanup previous interval on component unmount or when useEffect is re-executed
    return () => clearInterval(updateTablesDataInterval);

  }, []);

  useEffect(() => {
    getTables()
      .then(tablesResult => {
        tablesResult.data.data.forEach(table => table.access = null);
        getAccesses()
          .then(accesses => {
            let updatedTables = [...tablesResult.data.data];
            accesses.data.data.map(access => {
              if (access.valid) {
                let idx = updatedTables.findIndex(table => table._id === access.tableId);
                updatedTables[idx].access = access;
              }
            })
            setTables(updatedTables);
          })
      })
      .catch(e => {
        console.log(e.toString())
      })

  }, []);

  const updateTablesData = () => {
    getTables()
      .then(result => {
        setTables(prevState => {
          // merge updatedTables (with accesses) with result (with updated statuses)
          const mergeById = (a1, a2) =>
            a1.map(itm => ({
              ...a2.find((item) => (item._id === itm._id) && item),
              ...itm
            }));
          return mergeById(result.data.data, prevState,);
        })
      })
      .catch(e => {
        console.log(e.toString())
      })
  }

  const toggleFilter = (status) => {
    if (filteredStatus === status) {
      setFilteredStatus(null);
    } else {
      setFilteredStatus(status);
    }
  }

  const getTables = () => {
    const configuration = {
      method: "GET",
      url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/tables`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return axios(configuration);
  }

  const createAccess = (tableId) => {
    const configuration = {
      method: "POST",
      url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/accesses`,
      data: {
        tableId: tableId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return axios(configuration)
      .then((access) => {
        setTables((prevTables) => {
          const updatedTables = prevTables.map((table) =>
            table._id === access.data.data.tableId
              ? {...table, access: access.data.data, status: 1}
              : table
          );
          return updatedTables;
        });
      })
      .catch((e) => {
        console.log(e.toString());
      });
  };


  const getAccesses = () => {
    const configuration = {
      method: "GET",
      url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/accesses`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return axios(configuration);
  }

  const revokeAccess = (tableId, accessId) => {
    const configuration = {
      method: "PATCH",
      url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/accesses/revoke`,
      data: {
        tableId: tableId,
        accessId: accessId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return axios(configuration)
      .then(result => {
        let updatedTables = [...tables];
        updatedTables.forEach(table => {
          if (table._id === tableId) {
            table.access = null;
            table.status = 0;
          }
        })
        setTables(updatedTables);
      })
      .catch(e => {
        console.log(e.toString())
      });
  }

  const clearStatus = (tableId) => {
    const configuration = {
      method: "PATCH",
      url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/tables/${tableId}/clear`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return axios(configuration)
      .then(result => {
        setTables(prevState => {
          let updatedTables = [...prevState];
          updatedTables.forEach(table => {
            if (table._id === tableId) {
              table.status = 1;
            }
          })
          return updatedTables;
        })
      })
      .catch(e => {
        console.log(e.toString())
      });
  }

  const BlackButton = styled.div`
    background: black;
    color: white;
    padding: 10px 12px;
    font-weight: bold;
    border-radius: 5px;
  `

  const [showQRCodeModal, setShowQRCodeModal] = useState(false);
  const [accessIdBeingShown, setAccessIdBeingShown] = useState(null);

  return (
    <Container>
      <Row className="m-2">
        <Col>
          <Container>
            <Row>
              <Col className="text-center">
                <div className={filteredStatus === 0 ? "status-label-0 status-filtered-0" : "status-label-0"}
                     onClick={() => {
                       toggleFilter(0)
                     }}>
                  Empty
                </div>
              </Col>
              <Col className="text-center">
                <div className={filteredStatus === 1 ? "status-label-1 status-filtered-1" : "status-label-1"}
                     onClick={() => {
                       toggleFilter(1)
                     }}>
                  Occupied
                </div>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col className="text-center">
                <div className={filteredStatus === 2 ? "status-label-2 status-filtered-2" : "status-label-2"}
                     onClick={() => {
                       toggleFilter(2)
                     }}>
                  Assistance
                </div>
              </Col>
              <Col className="text-center">
                <div className={filteredStatus === 3 ? "status-label-3 status-filtered-3" : "status-label-3"}
                     onClick={() => {
                       toggleFilter(3)
                     }}>
                  Payment
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row>
        <Col className="p-0">
          <Accordion>
            {tables.map((table, idx) => {
              if (filteredStatus !== null) { // "if (filteredStatus)" doesn't work because of status 0
                if (table.status === filteredStatus) {
                  return <Accordion.Item eventKey={`${idx}`} style={{borderRadius: 0}}>
                    <Accordion.Header className={`table-item-${table.status}`}>
                      {table.name}
                    </Accordion.Header>
                    <Accordion.Body className="text-center px-4">
                      {
                        table.status > 1 ? <>
                          <div><span>{table.message}</span></div>
                          <BlackButton className="mt-2" onClick={() => clearStatus(table._id)}>Clear alert</BlackButton>
                        </> : ''
                      }
                      {
                        table.access ?
                          <>
                            <RevokeDiv revokeAccess={revokeAccess} table={table}/>
                            <BlackButton onClick={() => {
                              setAccessIdBeingShown(table.access._id);
                              setShowQRCodeModal(true);
                            }
                            }>Zoom QR code</BlackButton>
                          </>
                          :
                          <BlackButton onClick={() => createAccess(table._id)}>Create access</BlackButton>
                      }
                    </Accordion.Body>
                  </Accordion.Item>
                }
              } else {
                return <Accordion.Item eventKey={`${idx}`} style={{borderRadius: 0}}>
                  <Accordion.Header className={`table-item-${table.status}`}>
                    {table.name}
                  </Accordion.Header>
                  <Accordion.Body className="text-center px-4">
                    {
                      table.status > 1 ? <>
                        <div className="mt-2" style={{padding: "4px", fontWeight: "bold"}}>
                          <span>Message: {table.message}</span>
                        </div>
                        <BlackButton className="mt-2" onClick={() => clearStatus(table._id)}>Clear alert</BlackButton>
                      </> : ''
                    }
                    {
                      table.access ?
                        <>
                          <RevokeDiv revokeAccess={revokeAccess} table={table}/>
                          <BlackButton onClick={() => {
                            setAccessIdBeingShown(table.access._id);
                            setShowQRCodeModal(true);
                          }
                          }>Zoom QR code</BlackButton>
                        </>
                        :
                        <BlackButton onClick={() => createAccess(table._id)}>Create access</BlackButton>
                    }
                  </Accordion.Body>
                </Accordion.Item>
              }
            })}
          </Accordion>
        </Col>
      </Row>
      <Modal show={showQRCodeModal} onHide={() => setShowQRCodeModal(false)} centered style={{background: "black"}}>
        <Modal.Body onClick={() => setShowQRCodeModal(false)}>
          <QRCode
            size={256}
            style={{height: "auto", maxWidth: "100%", width: "100%"}}
            value={`https://menu.neo.menu/${accessIdBeingShown}`}
            viewBox={`0 0 256 256`}
          />
        </Modal.Body>
      </Modal>
    </Container>
  )
}