import QRCode from "react-qr-code";
import styled from "@emotion/styled";

export default function RevokeDiv(props) {

  const RedButton = styled.div`
    background: #ff3547;
    color: white;
    padding: 10px 12px;
    font-weight: bold;
    border-radius: 5px;
  `

  return(
    <div className="text-center mt-3 mb-3">
      <div style={{ height: "auto", margin: "0 auto", width: "50%" }}>
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={`https://menu.neo.menu/${props.table.access._id}`}
          viewBox={`0 0 256 256`}
        />
      </div>
      <RedButton className="mt-3" onClick={() => props.revokeAccess(props.table._id, props.table.access._id)}>Revoke access</RedButton>
    </div>
  )
}