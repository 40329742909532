import {Switch, Route, Redirect} from "react-router-dom";
import {Container, Navbar, Nav} from "react-bootstrap";
import Login from "./Login";
import Cookies from "universal-cookie";
import logo from '../src/img/logo.png'
import './App.css';
import ProtectedRoutes from "./ProtectedRoutes";
import Waiter from "./Waiter";

const cookies = new Cookies();

function App() {
  const logout = () => {
    cookies.remove("NEO_WAITER_TOKEN", {path: "/"});
    window.location.href = "/";
  }

  const token = cookies.get("NEO_WAITER_TOKEN");

  return (
    <>
      {token ?
        <Navbar style={{background: "white"}}>
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} alt="logo" height={22}/>
            </Navbar.Brand>
            <Navbar.Collapse>
              <Nav className="ms-auto">
                <div onClick={() => logout()}><Nav.Link>Logout</Nav.Link></div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        :
        <Navbar style={{background: "white"}}>
          <Container>
            <Navbar.Brand className="mx-auto" href="/">
              <img src={logo} alt="logo" height={22}/>
            </Navbar.Brand>
          </Container>
        </Navbar>
      }
      <div className="nav-separator"></div>

      <Switch>
        <Route exact path="/login" component={Login}/>
        {/* TODO: <GuestRoute /> */}
        <ProtectedRoutes path="/" component={Waiter}/>
      </Switch>
    </>
  );
}

export default App;
